import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { Heading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import { HoaSchema } from './hoa-api-client';
import { hoaService } from './ApiClients';
import {
    Field,
    FieldGroup,
    Fieldset,
    Label,
    Legend,
} from './Components/Catalyst/fieldset';
import { Textarea } from './Components/Catalyst/textarea';
import { Text } from './Components/Catalyst/text';
import { Input } from './Components/Catalyst/input';
import { Button } from './Components/Catalyst/button';
import { Checkbox } from './Components/Catalyst/checkbox';

const HOADetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [hoa, setHoa] = useState<HoaSchema | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchHoa = async () => {
            try {
                setLoading(true);
                const response = await hoaService.getHoaById({
                    hoaId: Number(id),
                });
                setHoa(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching HOA:', err);
                setError(
                    'Failed to fetch HOA details. Please try again later.'
                );
            } finally {
                setLoading(false);
            }
        };

        fetchHoa();
    }, [id]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        if (!hoa) return;

        try {
            await hoaService.updateHoa({
                hoaId: hoa.id,
                hoaCreateOrUpdateSchema: hoa,
            });
            setIsEditing(false);
            // Optionally, refetch the HOA data to ensure we have the latest version
            const response = await hoaService.getHoaById({
                hoaId: Number(id),
            });
            setHoa(response.data);
        } catch (err) {
            console.error('Error updating HOA:', err);
            setError('Failed to update HOA. Please try again later.');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        // Optionally, reset the HOA data to its original state
        // This assumes you're keeping the original state somewhere
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this HOA?')) {
            try {
                await hoaService.deleteHoa({ hoaId: Number(id) });
                navigate('/hoas');
            } catch (err) {
                console.error('Error deleting HOA:', err);
                setError('Failed to delete HOA. Please try again later.');
            }
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (!hoa) return;
        const { name, value } = e.target;
        setHoa({ ...hoa, [name]: value });
    };

    const handleCheckboxChange = (checked: boolean) => {
        if (!hoa) return;
        setHoa({ ...hoa, is_active: checked });
    };

    if (loading) {
        return <div>Loading HOA details...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!hoa) {
        return <div>No HOA found with the given ID.</div>;
    }

    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage="hoa_detail" />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            <div className="max-w-3xl mx-auto py-8">
                <div className="flex justify-between items-center mb-6">
                    <Heading level={2}>{hoa.name}</Heading>
                    <div>
                        {isEditing ? (
                            <>
                                <Button onClick={handleSave} className="mr-2">
                                    Save
                                </Button>
                                <Button onClick={handleCancel} color="zinc">
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={handleEdit} className="mr-2">
                                    Edit
                                </Button>
                                <Button onClick={handleDelete} color="red">
                                    Delete
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <Divider className="mb-6" />

                <Fieldset>
                    <Legend>HOA Information</Legend>
                    <FieldGroup>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <Field>
                                <Label>HOA Name</Label>
                                <Input
                                    name="name"
                                    value={hoa.name}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>Status</Label>
                                <div className="flex items-center">
                                    <Checkbox
                                        checked={hoa.is_active}
                                        onChange={handleCheckboxChange}
                                        disabled={!isEditing}
                                    />
                                    <Text className="ml-2">
                                        {hoa.is_active ? 'Active' : 'Inactive'}
                                    </Text>
                                </div>
                            </Field>
                            <Field>
                                <Label>Contact Email</Label>
                                <Input
                                    name="contact_email"
                                    value={hoa.contact_email || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>Contact Phone</Label>
                                <Input
                                    name="contact_phone"
                                    value={hoa.contact_phone || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>Website URL</Label>
                                <Input
                                    name="website_url"
                                    value={hoa.website_url || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                        </div>
                    </FieldGroup>
                </Fieldset>

                <Fieldset className="mt-8">
                    <Legend>Address</Legend>
                    <FieldGroup>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <Field className="sm:col-span-2">
                                <Label>Street</Label>
                                <Input
                                    name="street"
                                    value={hoa.street || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field className="sm:col-span-2">
                                <Label>Street 2</Label>
                                <Input
                                    name="street2"
                                    value={hoa.street2 || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>City</Label>
                                <Input
                                    name="city"
                                    value={hoa.city || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>State</Label>
                                <Input
                                    name="state"
                                    value={hoa.state || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>ZIP Code</Label>
                                <Input
                                    name="zip"
                                    value={hoa.zip || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>Country</Label>
                                <Input
                                    name="country"
                                    value={hoa.country || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                        </div>
                    </FieldGroup>
                </Fieldset>

                <Fieldset className="mt-8">
                    <Legend>Additional Information</Legend>
                    <FieldGroup>
                        <Field>
                            <Label>Description</Label>
                            <Textarea
                                name="description"
                                value={hoa.description || ''}
                                readOnly={!isEditing}
                                onChange={handleInputChange}
                            />
                        </Field>
                    </FieldGroup>
                </Fieldset>
            </div>
        </SidebarLayout>
    );
};

export default HOADetailPage;
